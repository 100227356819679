<template>
  {{ props.column.label }}
  <template v-if="props.sortable">
    <WidgetTableColumnSort
      :prop="props.prop"
      :default-sort="defaultSort"
      @sort-change="s => emit('filterSortChange', s)" />
  </template>
  <template v-if="props.filter">
    <component
      :is="filterTypeComponentsMap[props.filterType]"
      @filter-change="emit('filterSortChange')" />
  </template>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';
import WidgetTableColumnSort from '@/components/widgets/table/sort/WidgetTableColumnSort'

const props = defineProps({
  column: {
    type: Object,
    default: () => {}
  },
  prop: {
    type: String,
    default: ''
  },
  sortable: {
    type: Boolean,
    default: false
  },
  defaultSort: {
    type: String,
    default: 'descending'
  },
  filter: {
    type: Boolean,
    default: false
  },
  filterType: {
    type: String,
    default: 'date'
  }
})
const emit = defineEmits([
  'filterSortChange'
])
const filterTypeComponentsMap = {
  date: defineAsyncComponent(() => {
    return import('@/components/widgets/table/filters/WidgetTableColumnFilterDate')
  }),
  number: defineAsyncComponent(() => {
    return import('@/components/widgets/table/filters/WidgetTableColumnFilterNumber')
  }),
  select: defineAsyncComponent(() => {
    return import('@/components/widgets/table/filters/WidgetTableColumnFilterSelect')
  }),
  text: defineAsyncComponent(() => {
    return import('@/components/widgets/table/filters/WidgetTableColumnFilterText')
  })
}
</script>
