<template>
  <el-dialog
    v-model="visible"
    title="Создание отчёта"
    width="1200px"
    @closed="$emit('update:formShowed', false)">
    <ReportForm
      v-model:visible="visible"
      @reportSaved="$emit('reportSaved')" />
  </el-dialog>
</template>

<script>
import { ref, onMounted } from 'vue'
import ReportForm from './ReportForm.vue'
export default {
  components: {
    ReportForm
  },
  props: {
    formShowed: Boolean
  },
  emits: ['update:formShowed', 'reportSaved'],
  setup (props, ctx) {
    const visible = ref(false)

    onMounted(() => {
      visible.value = true
    })

    return {
      visible
    }
  }
}

</script>
