import { cloneDeep, merge } from 'lodash'
import Pagination from './pagination'
export default class Query {
  constructor (query = {}) {
    this.defaultQuery = cloneDeep(query)
    this.f = query.f
    this.s = query.s
    this.p = new Pagination(query.p)
  }

  buildQuery (query) {
    if (!query) return
    const { f, s, p } = query
    if (s) {
      const { prop, order } = s
      merge(this.s, { prop, order })
      if (s && !p) {
        this.p.resetPage()
      }
    }
    if (f) {
      merge(this.f, f)
    }
    if (p) {
      this.p.buildPagination(p)
    }
  }

  resetQuery () {
    this.f = cloneDeep(this.defaultQuery.f)
  }

  getFormattedSort ({ prop = '', order = '' }) {
    if (!prop || !order) this.s = {}
    return {
      [prop]: order?.replace(/(desc|asc)ending/, '$1')
    }
  }

  get data () {
    const { f, s, p } = this
    return {
      f,
      s: this.getFormattedSort(s),
      p: p.query
    }
  }

  get routerData () {
    const { f, s, p } = this
    return {
      f,
      s,
      p: p.query
    }
  }
}
