<template>
  <span
    class="caret-wrapper"
    :class="currentSortDirection"
    @click="changeSortDirection">
    <i class="sort-caret ascending" />
    <i class="sort-caret descending" />
  </span>
</template>

<script setup>
import { ref, onBeforeMount, watch } from 'vue'
const props = defineProps({
  prop: {
    type: String,
    default: '',
    required: true
  },
  defaultSort: {
    type: String,
    default: 'descending'
  }
})

onBeforeMount(() => {
  setSortOrder()
})

const currentSortDirection = ref(props.defaultSort)
watch(
  () => props.defaultSort,
  (newVal) => {
    if(!newVal && newVal !== currentSortDirection.value) {
      setSortOrder(newVal)
      currentSortDirection.value = newVal
    }
  }
)

const sortOrder = ref([])
function setSortOrder (value = props.defaultSort) {
  let currentOrder = []
  if (value === 'descending') {
    currentOrder = ['descending', 'ascending', '']
  } else if (value === 'ascending') {
    currentOrder = ['ascending', 'descending', '']
  } else {
    currentOrder = ['', 'descending', 'ascending']
  }
  sortOrder.value = currentOrder
}

function changeSortDirection () {
  const indexOfCurrentSort = sortOrder.value.indexOf(currentSortDirection.value)
  let nextSortDirection = (indexOfCurrentSort < sortOrder.value.length - 1) ?
    sortOrder.value[indexOfCurrentSort + 1] :
    sortOrder.value[0]
  currentSortDirection.value = nextSortDirection
  emitSortChangeEvent(nextSortDirection)
}

const emit = defineEmits(['sort-change'])
function emitSortChangeEvent (sortDirection) {
  let sortEventData = {
    s: {
      prop: null,
      order: null
    }
  }
  if (sortDirection) {
    sortEventData.s = {
      prop: props.prop,
      order: sortDirection
    }
  }
  emit('sort-change', sortEventData)
}
</script>

<style lang="scss" scoped>
.caret-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 14px;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;

  .sort-caret {
    width: 0;
    height: 0;
    border: solid 5px transparent;
    position: absolute;
    left: 7px;
    &.ascending {
      top: -4px;
      border-bottom-color: #c0c4cc;
    }
    &.descending {
      bottom: -3px;
      border-top-color: #c0c4cc;
    }
  }
  &.descending {
    .sort-caret {
      &.descending {
        border-top-color: #1B71C7;
      }
    }
  }
  &.ascending {
    .sort-caret {
      &.ascending {
        border-bottom-color: #1B71C7;
      }
    }
  }
}
</style>