export default class Pagination {
  constructor (config = {}) {
    this.page = Number(config.page)
    this.per_page = Number(config.per_page)
    this.total = Number(config.total)
  }

  buildPagination ({ page = this.page, per_page = this.per_page }) {
    this.page = Number(page)
    this.per_page = Number(per_page)
  }

  setTotal ({ total }) {
    this.total = total
  }

  resetPage () {
    this.page = 1
  }

  get query () {
    return {
      page: this.page,
      per_page: this.per_page,
      total: this.total
    }
  }
}
