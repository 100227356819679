<template>
  <div class="report-list">
    <el-row
      :gutter="20"
      class="mb-20">
      <el-col :span="10">
        <WidgetFilter
          width="650px"
          @resetFilter="resetFilter"
          @submitFilter="getReportList"
          @searchInput="val => getReportList({f: {search: {search: val}}})">
          <template #fields>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item>
                  <el-date-picker
                    v-model="query.data.f.report_date.eq"
                    v-mask="'##.##.####'"
                    type="date"
                    placeholder="Дата отчета"
                    format="DD.MM.YYYY"
                    value-format="YYYY-MM-DD"
                    clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-date-picker
                    v-model="query.data.f.period.in"
                    v-mask="'##.##.####'"
                    type="daterange"
                    start-placeholder="Начало периода"
                    end-placeholder="Конец периода"
                    format="DD.MM.YYYY"
                    value-format="YYYY-MM-DD"
                    clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-select
                    id="5ll7mnwbu"
                    v-model="query.data.f.status_id.in"
                    class="full-width"
                    default-first-option
                    multiple
                    clearable
                    :placeholder="$t('Статус')">
                    <el-option
                      v-for="item in reportStatusList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id.toString()" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-input
                    v-model="query.data.f.id.eq"
                    type="integer"
                    placeholder="ID"
                    clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-select
                    id="95l8egpmg"
                    v-model="query.data.f.contract_id.eq"
                    class="full-width"
                    filterable
                    :collapse-tags="true"
                    reserve-keyword
                    clearable
                    :placeholder="$t('Договор')"
                    remote
                    :remote-method="getContractList">
                    <el-option
                      v-for="item in contractList"
                      :key="item.id"
                      :label="item.contract_number"
                      :value="item.id.toString()" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </WidgetFilter>
      </el-col>
      <el-col
        :span="14"
        align="right">
        <el-button
          type="primary"
          @click="formShowed = true">
          {{ $t("Создать") }}
        </el-button>
      </el-col>
    </el-row>
    <el-row
      class="mb-20"
      :gutter="20">
      <el-col>
        <el-table
          v-loading="loading"
          :data="reportData"
          class="mb-20 reportListTable"
          border
          height="calc(100vh - 189px)">
          <el-table-column
            label="Дата отчета"
            align="center"
            width="230"
            prop="report_date">
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="report_date"
                sortable
                :default-sort="query.s.prop === 'report_date' ? query.s.order : ''"
                @filterSortChange="getReportList" />
            </template>
            <template #default="report">
              {{ $filters.convertDate(report.row.report_date) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="id"
            label="ID отчета"
            align="center"
            min-width="200">
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="id"
                sortable
                :default-sort="query.s.prop === 'id' ? query.s.order : ''"
                @filterSortChange="getReportList" />
            </template>
            <template #default="report">
              {{ report.row.id || '-' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="report_number"
            min-width="160"
            label="Аппарат">
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="report_number" />
            </template>
            <template #default="report">
              <template v-if="report.row.report_number">
                <router-link
                  :key="report.row.id"
                  target="_blank"
                  :to="{ name: 'ReportItem', params: { id: report.row.id }}">
                  {{ report.row.report_number }}
                </router-link>
              </template>
              <template v-else>
                -
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="contract"
            align="center"
            min-width="100"
            label="Договор">
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="contract" />
            </template>
            <template #default="report">
              <template v-if="report.row.contract">
                <!-- TODO после реализации карточки договора
                <router-link
                  :key="report.row.contract_id"
                  :to="{ name: 'ReportItem', params: { id: report.row.contract_id }}">
                  {{ report.row.contract.contract_number || '(б/н)' }}
                </router-link>
                -->
                {{ report.row.contract.contract_number || '(б/н)' }}
              </template>
              <template v-else>
                -
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="subject"
            align="center"
            min-width="100"
            label="Субъект">
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="subject" />
            </template>
            <template #default="report">
              <template v-if="report.row.contract?.subject">
                <!-- TODO после реализации карточки субъекта
                <router-link
                  :key="report.row.subject_id"
                  :to="{ name: 'ReportItem', params: { id: report.row.contract.subject_id }}">
                  {{ report.row.contract.subject.title || '-' }}
                </router-link>
                -->
                {{ report.row.contract.subject.title || '-' }}
              </template>
              <template v-else>
                -
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="sum_report"
            align="center"
            min-width="200"
            label="Сумма отчета">
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="sum_report"
                sortable
                :default-sort="query.s.prop === 'sum_report' ? query.s.order : ''"
                @filterSortChange="getReportList" />
            </template>
            <template #default="report">
              {{ $filters.convertPrice(report.row.sum_report) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sum_report_paid"
            align="center"
            min-width="100"
            label="Оплачено">
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="sum_report_paid" />
            </template>
            <template #default="report">
              {{ $filters.convertPrice(report.row.sum_report_paid) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Период"
            align="center"
            width="180">
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="period_date_beg"
                sortable
                default-sort=""
                @filterSortChange="getReportList" />
            </template>
            <template #default="report">
              {{ $filters.convertDate(report.row.period_date_beg) }} - {{ $filters.convertDate(report.row.period_date_end) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="status_title"
            label="Статус"
            align="center"
            width="200">
            <!-- Временно убрал фильтр
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="status_title"
                filter
                filter-type="select"
                @filterSortChange="getReportList" />
            </template>
            -->
            <template #default="report">
              {{ report.row.status_title || '-' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="new_attachments"
            label="Файлы"
            width="100"
            align="center">
            <template #header="{column}">
              <WidgetTableColumnHeader
                :column="column"
                prop="new_attachments" />
            </template>
            <template #default="report">
              <template v-if="report.row.attachments.length">
                <el-popover
                  v-if="report.row.attachments.length"
                  placement="left"
                  width="200"
                  trigger="hover">
                  <el-button
                    v-for="(file, index) in report.row.attachments"
                    :key="index"
                    class="full-width report-list__file-btn"
                    type="primary"
                    @click="downloadFile(file.id)">
                    <i class="el-icon-download" />
                    {{ file.filename.substring(0, 50) }}
                  </el-button>
                  <template #reference>
                    <el-button
                      v-if="report.row.attachments.length"
                      type="text">
                      {{ 'Файлы' }}
                    </el-button>
                  </template>
                </el-popover>
              </template>
              <template v-else>
                -
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="Действия"
            align="center"
            min-width="100" />
        </el-table>
        <el-pagination
          background
          align="left"
          layout="prev, pager, next"
          :current-page="query.p.page"
          :page-size="query.p.per_page"
          :total="query.p.total"
          @current-change="value => getReportList({p: {page: value} })" />
      </el-col>
    </el-row>
    <ReportAddDialog
      v-if="formShowed"
      v-model:formShowed="formShowed"
      @reportSaved="getReportList" />
  </div>
</template>

<script>
import _merge from 'lodash/merge'
import { Query } from '@/classes'
import { REPORT, FILE, SOUND_DEVICE, CONTRACT } from '@/api'
import WidgetFilter from '@/components/widgets/WidgetFilter'
import ReportAddDialog from './ReportAddDialog'
import WidgetTableColumnHeader from '@/components/widgets/table/WidgetTableColumnHeader'

export default {
  name: 'ReportList',
  components: {
    WidgetFilter,
    ReportAddDialog,
    WidgetTableColumnHeader,
  },
  data () {
    return {
      search: undefined,
      formShowed: false,
      karaokeList: [],
      subjectList: [],
      objectList: [],
      contractList: [],
      reportData: [],
      loading: false,
      query: new Query({
        f: {
          period: {
            in: []
          },
          search: {
            search: undefined
          },
          report_date: {
            eq: undefined
          },
          contract_id: {
            eq: undefined
          },
          object_id: {
            eq: undefined
          },
          subject_id: {
            eq: undefined
          },
          karaoke_id: {
            eq: undefined
          },
          karaoke_producer: {
            eq: undefined
          },
          id: {
            eq: undefined
          },
          status_id: {
            in: []
          }
        },
        s: {
          order: 'descending',
          prop: 'report_date'
        },
        p: {
          page: 1,
          per_page: 20,
          total: null
        }
      })
    }
  },

  computed: {
    ...mapGetters('report', ['reportStatusList'])
  },

  created () {
    this.getReportList(this.$route.query)
  },

  methods: {

    getReportTypeList () {
      this.$http.get(REPORT.TYPE_LIST).then(response => {
      })
    },

    async getReportList (data) {
      this.loading = true
      this.query.buildQuery(data)
      await this.$router.replace({ query: _merge({}, this.query.routerData) })
      this.$http.get(REPORT.LIST, { params: this.query.data }).then(r => {
        this.reportData.length = []
        this.reportData = r.data.list
        this.query.p.setTotal(r.params.p)
      }).finally(() => {
        this.loading = false
      })
    },

    downloadFile (id) {
      this.$http.get(FILE.GET_URL, { params: { id: id } }).then(response => {
        window.location = response.data.url
      })
    },

    resetFilter () {
      this.query.resetQuery()
    },

    getContractList (val) {
      const params = {
        f: {
          search: { search: val }
        }
      }
      this.$http.get(CONTRACT.LIST, { params }).then(r => {
        this.contractList = r.data.list
      })
    },

    getSubjectList () {

    },

    getObjectList () {

    },

    getKaraokeList (val) {
      const params = {
        f: {
          identifier: { ilike: val },
          systype_id: { eq: 2 }
        },
        scope: 'complete'
      }
      this.$http.get(SOUND_DEVICE.LIST, { params }).then(r => {
        this.karaokeList = r.data.list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .report-list {
    &__file-btn {

    }
    .el-dialog {
    }
    fieldset {
      legend {
        padding: 0 0.5em;
      }
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      padding-bottom: 0;
    }
  }
</style>

<style lang="scss">
  .report-list {
    .el-table.reportListTable .el-table__cell {
      vertical-align: top;
    }
  }
</style>
