<template>
  <el-row
    class="widget-filter"
    :gutter="20">
    <el-col
      v-if="search"
      :span="10">
      <el-input
        id="filter-search"
        v-model="query.f.search.search"
        placeholder="Поиск..."
        clearable
        @input="checkSearchFilter" />
    </el-col>
    <el-col :span="14">
      <el-badge
        :value="calcCountFilter"
        :hidden="!calcCountFilter">
        <el-button
          id="filter-btn"
          class="widget-filter__button"
          type="primary"
          @click="visible = true">
          <el-icon><Filter /></el-icon>
          <span>{{ $t('Фильтр') }}</span>
        </el-button>
      </el-badge>
      <el-button
        v-if="calcCountFilter"
        id="filter-counter"
        class="widget-filter__reset"
        text
        @click="resetFilter">
        {{ $t('Сбросить') }}
      </el-button>
      <el-dialog
        v-model="visible"
        :title="$t('Фильтр')"
        :close-on-click-modal="false"
        :width="width">
        <el-form
          ref="widgetFilterForm"
          class="widget-filter__form"
          :model="fields">
          <slot name="fields" />
        </el-form>
        <template #footer>
          <el-button
            id="filter-reset"
            :disabled="!calcCountFilter"
            @click="resetFilter">
            Сбросить
          </el-button>
          <el-button
            id="filter-apply"
            type="primary"
            @click="submitFilter">
            Применить
          </el-button>
        </template>
      </el-dialog>
    </el-col>
  </el-row>
</template>

<script>
// import merge from 'lodash/merge'
import { Filter } from '@element-plus/icons-vue'
import qs from 'qs'
import { debounce } from 'lodash'

export default {
  name: 'WidgetFilter',
  components: {
    Filter
  },
  props: {
    search: {
      type: Boolean,
      default: true
    },
    change: {
      type: Function,
      default: () => {}
    },
    fields: {
      type: Object,
      default: () => {}
    },
    width: {
      type: String,
      default: '450px'
    }
  },
  emits: ['resetFilter', 'submitFilter', 'searchInput'],
  data () {
    return {
      visible: false,
      query: {
        f: {
          search: {
            search: undefined
          }
        }
      }
    }
  },
  computed: {
    filterKeys () {
      const { f = {}, fs = {} } = qs.parse(this.$route.fullPath.split('?')[1], { arrayFormat: 'brackets' })
      return Object.keys({ ...f, ...fs })
    },
    calcCountFilter () {
      return this.filterKeys.length
    }
  },
  created () {
  },
  methods: {
    resetFilter () {
      this.$emit('resetFilter')
      this.submitFilter()
    },

    submitFilter () {
      this.visible = false
      this.$emit('submitFilter', { p: { page: 1 } })
    },

    checkSearchFilter: debounce(function (val) {
      if (!val || val.length > 2) this.$emit('searchInput', val)
    }, 200)
  }
}
</script>

<style lang="scss" scoped>
  .widget-filter {
    &__reset {
      vertical-align: middle;
      margin-left: 20px;
    }
  }
</style>
